import React from "react";
import Button from "./button";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick?: () => void;
  variant?: "cancel" | "send";
}

interface ModalButtonsProps {
  buttons: ButtonProps[];
}

const ModalButtons: React.FC<ModalButtonsProps> = ({ buttons }) => {
  return (
    <div className="flex justify-center gap-2.5 mt-4">
      {buttons.map((button, index) => (
        <Button
          key={index}
          variant={button.variant || "primary"}
          onClick={button.onClick}
          {...button}
        >
          {button.text}
        </Button>
      ))}
    </div>
  );
};

export default ModalButtons;