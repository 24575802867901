export const getJwtToken = (): string | null => {
  const token = localStorage.getItem("jwtToken");

  // Add validation to ensure token is valid
  if (token) {
    try {
      // Check if token is expired
      const payload = JSON.parse(atob(token.split('.')[1]));
      if (payload.exp * 1000 < Date.now()) {
        localStorage.removeItem("jwtToken");
        return null;
      }
      return token;
    } catch {
      localStorage.removeItem("jwtToken");
      return null;
    }
  }
  return null;
};

export const isAuthenticated = (): boolean => {
  const token = getJwtToken();
  if (!token) return false;

  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 > Date.now();
  } catch {
    return false;
  }
};

export const logout = (): void => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("tolk_chat_id");
  localStorage.removeItem("scribe_chat_id");
  // Use window.location.href with hash router
  window.location.href = '/#/login';
};
