// src/components/LogoAnimation.tsx

import React, { useEffect, useState } from 'react';
import './css/LogoAnimation.css'; // Import the CSS for styling

const LogoAnimation: React.FC<{ onAnimationEnd: () => void }> = ({ onAnimationEnd }) => {
    const [isAnimating, setIsAnimating] = useState(true);
    
    useEffect(() => {
      const timer = setTimeout(() => {
        onAnimationEnd(); // Call the callback function after the animation duration
      }, 3000); // Adjust the duration to match the length of your animation
  
      return () => clearTimeout(timer);
    }, [onAnimationEnd]);

  if (!isAnimating) {
    return null; // Return null when animation is not happening
  }

  return (
    <div className="logo-animation">
      <img src="./logo.png" alt="Logo" className="logo" />
    </div>
  );
};

export default LogoAnimation;
