// config.js
const config = {
  // reactServer: process.env.REACT_APP_SERVER,
  reactServer: process.env.REACT_APP_SERVER || "http://localhost:5001/",
  // reactServer: process.env.REACT_APP_SERVER || 'https://OTAP-WellcomAdmin.eu.pythonanywhere.com/',
  // reactServer: process.env.REACT_APP_SERVER || 'https://LIVE-WellcomAdmin.eu.pythonanywhere.com/',
  // reactServer: process.env.REACT_APP_SERVER || 'https://thijsrood.pythonanywhere.com/',
};

export default config;
