import React, { ButtonHTMLAttributes } from "react";
import { baseStyles, buttonVariants } from "./buttonVariants";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof buttonVariants;
}

const Button: React.FC<ButtonProps> = ({ children, variant = "primary", className = "", ...props }) => {
  return (
    <button
      className={`${baseStyles} ${buttonVariants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
