import React, { createContext, useState, useContext, useEffect } from 'react';
import { getJwtToken, logout } from '../auth';
import config from '../config';

const reactServer = config.reactServer;

interface ProfileContextType {
  profile: any; // Replace 'any' with proper interface for your profile data
  loadingProfile: boolean;
  errorProfile: string | null;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [errorProfile, setErrorProfile] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = getJwtToken();

        if (token) {
          const response = await fetch(reactServer + "account/profile", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            if (response.status === 401) {
              logout(); // Unauthorized, clear session
            }
            throw new Error('Failed to fetch profile');
          }

          const data = await response.json();
          setProfile(data);
          setLoadingProfile(false);
        } else {
          setLoadingProfile(false);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setErrorProfile(error.message);
        setLoadingProfile(false);
      }
    };

    fetchProfile();
  }, []);

  return (
    <ProfileContext.Provider value={{ profile, loadingProfile, errorProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export function useProfile() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
}