export const baseStyles = "";

export const buttonVariants = {
    primary: "bg-[#70c1a1] text-white text-base p-2.5 px-5 border-0 rounded-md cursor-pointer transition-all duration-200 hover:bg-[#5a9d89] w-32",
    cancel: "bg-[#f44336] text-white text-base p-2.5 px-5 border-0 rounded-md cursor-pointer transition-all duration-200 hover:bg-[#d32f2f] w-32",
    send: "bg-[#70c1a1] text-white text-base p-2.5 px-5 border-0 rounded-md cursor-pointer transition-all duration-200 hover:bg-[#5a9d89] w-32",
    secondary: "bg-gray-500 text-white hover:bg-gray-600",
    danger: "bg-red-500 text-white hover:bg-red-600",
    success: "bg-green-500 text-white hover:bg-green-600",
    warning: "bg-yellow-500 text-black hover:bg-yellow-600",
};
