import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useProfile } from '../functions/ProfileContext';
import { getJwtToken } from '../auth';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { profile, loadingProfile } = useProfile();
    const location = useLocation();
    const token = getJwtToken();

    if (loadingProfile) {
        return <div className="loading-spinner">Loading...</div>;
    }

    if (!token || !profile?.naam) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;