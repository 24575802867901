import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { getJwtToken } from "../auth";
import config from "../config";
import './css/landingpage.css';

const reactServer = config.reactServer;

const LandingPage: React.FC = () => {
    const [profile, setProfile] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            const token = getJwtToken();
            if (token) {
                try {
                    const response = await fetch(`${reactServer}account/profile`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    setProfile(data);

                } catch (error) {
                    console.error("Error fetching profile:", error);
                }
            }
        };

        fetchProfile();
    }, []);

    const usage = Math.ceil(profile?.usage) || 0;
    const limit = profile?.limit !== undefined ? `${profile.limit} chat's` : "Onbeperkt";

    const subscriptions = Array.isArray(profile?.subscription) ? profile.subscription : [];
    const activeSubscription = subscriptions
        .filter((sub: any) => sub.pricing_id !== 1)
        .find((sub: any) => sub.status === "active") 
        || subscriptions.find((sub: any) => sub.status === "active"); 

    const activeSubscriptionName = activeSubscription
        ? activeSubscription.pricing.name
        : "Geen actief abonnement";

    const patientCount = profile?.patienten?.length ?? 0;
                
    const timePerChat = 5; 
    const timeSaved = usage * timePerChat;
    const timeSavedMin = `${timeSaved} minuten`;

    return (
        <div className="lp-container">
            <div className="lp-chat-container lp-chat-1">
                <div className="lp-logo-container">
                    <img
                        src="./TClogo.jpeg"
                        alt="Logo"
                        className="lp-tc-logo"
                    />
                </div>
                <div className="lp-chat-text">
                    <p>
                        Communiceer zonder taalbarrières. Tolkchat helpt je om gesprekken met je patiënten direct en eenvoudig te vertalen.
                    </p>
                </div>
                <button className="lp-button" onClick={() => navigate("../tolkchat")}>
                    Naar Tolkchat
                </button>
            </div>
            <div className="lp-chat-container lp-chat-2">
                <div className="lp-logo-container">
                    <img
                        src="./SClogo.jpeg"
                        alt="Logo"
                        className="lp-sc-logo"
                    />
                </div>
                <div className="lp-chat-text">
                    <p>
                        Bespaar tijd en focus op je patiënt. Scribechat zet je gesprekken automatisch om in nauwkeurige transcripties.                    
                    </p>
                </div>
                <button className="lp-button" onClick={() => navigate("../scribechat")}>
                        Naar Scribechat
                </button>
            </div>

            <div className="lp-btm-container-1">
                <div className="lp-btm-box" onClick={() => navigate("../abonnement")}>
                    <p>Abonnement</p>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>
                    </div>
                    <p style={{ fontWeight: "bold" }}>
                        {activeSubscriptionName}
                    </p>
                    <button className="lp-button-box bg-gray-200 text-sm">
                        {activeSubscription && activeSubscription.pricing.name !== "Demo" 
                            ? "Naar abonnement" 
                            : "Upgrade hier"}
                    </button>
                </div>
                <div className="lp-btm-box" onClick={() => navigate("../patient-management")}>
                    <p>Patiënten</p>
                    <div>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="size-6"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 14.2354V17.0001C9 19.7615 11.2386 22.0001 14 22.0001H14.8824C16.7691 22.0001 18.3595 20.7311 18.8465 19.0001" stroke="currentColor" strokeWidth="1.5"></path> <path d="M5.42857 3H5.3369C5.02404 3 4.86761 3 4.73574 3.01166C3.28763 3.13972 2.13972 4.28763 2.01166 5.73574C2 5.86761 2 6.02404 2 6.3369V7.23529C2 11.1013 5.13401 14.2353 9 14.2353C12.7082 14.2353 15.7143 11.2292 15.7143 7.521V6.3369C15.7143 6.02404 15.7143 5.86761 15.7026 5.73574C15.5746 4.28763 14.4267 3.13972 12.9785 3.01166C12.8467 3 12.6902 3 12.3774 3H12.2857" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path> <circle cx="19" cy="16" r="3" stroke="currentColor" strokeWidth="1.5"></circle> <path d="M12 2V4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M6 2V4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path> </g></svg>
                    </div>
                    <p style={{ fontWeight: "bold" }}>
                        {patientCount}
                    </p>
                    <button className="lp-button-box bg-gray-200 text-sm">
                        Naar patient
                    </button>
                </div>
            </div>

            <div className="lp-btm-container-2">
                <div className="lp-btm-box" onClick={() => navigate("../statistieken")}>
                    <p>Chat's gebruikt</p>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                        </svg>
                    </div>
                    <p style={{ fontWeight: "bold" }}>
                        {usage} / {limit}
                    </p>
                    <button className="lp-button-box bg-gray-200 text-sm">
                        Naar statistieken
                    </button>
                </div>
                <div className="lp-btm-box" onClick={() => navigate("../statistieken")}>
                    <p>Tijd bespaard</p>
                    <div>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            strokeWidth="1.5" 
                            stroke="currentColor" 
                            className="size-6"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" 
                            />
                        </svg>
                    </div>
                    <p style={{ fontWeight: "bold" }}>{timeSavedMin}</p>
                    <button className="lp-button-box bg-gray-200 text-sm">
                        Naar Statistieken
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;